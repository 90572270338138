* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.carousel-container {
    max-width: 100%; /* Set the maximum width for the carousel */
    margin: 0 auto; /* Center the carousel on the page */
    margin-right: 20px; 
    margin-top: 84px;
    
}  

.carousel-image {
    height: 93px; /* Set your desired height for the carousel slides here */
  }
  
  /* Add any additional styles as needed */

  .wid{
    width: 0px;
  }

  .gio{
    
    width: 144px;
}

.slick-slider {
    margin-top: 18px;
}



.logos-slide img {
    height: 81px;
    margin-right: 20px; 
}

.logos-slide img {
    height: 81px;
    margin-right: 10px; /* Adjust the margin-right value to reduce space from the right side */
}


.logos {
    height: 155px; /* Adjust the height as needed */
    
    margin-top: 6%;
    margin-bottom: 8%;
    white-space: nowrap;
    position: relative;
    overflow: hidden; 
    overflow-x: hidden; 
}

.vtp {
    margin-right: 31px;
    margin-left: 21px;
}

.logos::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 307px;
    height: 150%; 
    background-color: #EEEEEE;
    z-index: 2;
    content: "Our Brand";
    color: #999995;
    font-size: 32px;
    padding: 21px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-right: 17px;
    transform: translateY(2px);
    padding-left: 74px;
    
}


.logos::after {
    position: absolute;
    top: 60px; /* Adjust the top position to move "Partnership" below "Our Brand" */
    left: 0;
    width: 307px;
    height: 150%;
    background-color: #EEEEEE;
    z-index: 2;
    content: "Partnerships";
    color: #F2833E;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-left: 96px;
}



.logos-slide {
    display: inline-block;
    animation: 10s slide infinite linear; /* Adjust duration as needed */
    margin-top: 38px;
    margin: 0 5px;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .carousel-container {
        max-width: 100%;
        margin-top: 20px;
    }
  
    .gio {
        width: 100%;
    }
  
    .logos-slide img {
        height: 60px;
    }

    .logos::after {
        position: absolute;
        top: 60px;
        left: 0;
        width: 152px;
        height: 65px; /* Set the height for the gray background in mobile responsive mode */
        background-color: #EEEEEE;
        z-index: 2;
        content: "Partnerships";
        color: #F2833E;
        font-size: 18px;
        padding: 10px;
        text-align: center;
        white-space: pre-wrap;
        font-weight: 600;
        padding-left: 18px;
    }

    .logos::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 152px;
        height: 65px;
        background-color: #EEEEEE;
        z-index: 2;
        content: "Our Brand";
        color: #999995;
        font-size: 19px;
        padding: 31px;
        text-align: center;
        white-space: pre-wrap;
        font-weight: 600;
        padding-right: 9px;
        transform: translateY(2px);
        padding-left: 0px;
    }

    .logos-slide {
        display: inline-block;
        animation: 10s slide infinite linear; /* Adjust duration as needed */
        margin-top: 38px;
        margin: 0 5px;
    }

    .logos:hover .logos-slide {
        animation-play-state: paused;
    }

    .sideing {
        padding-left: 63px;
    }

    .lefting {
        margin-left: 33px;
    }

    .hiding {
        display: none;
    }

    .heightof{
        height: 73px;
    }

    .heightinn{
        height: 70px;
    }

    .adjust {
        margin-left: 88px;
    }
    .slick-slider{
        margin-top: 26px;
    }
  
}
