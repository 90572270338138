@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.slider-container {
  position: relative;
  width: 100%;
 
  margin: 0 auto; /* Center the slider container */
}

.slider-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.secondimg{
  width: 100vw;
  height: 74vh;
  margin-top: 20px;
}
/* CSS for overlay */
.overlay {
  position: relative;
 
}

.first{
  width: 100vw;
  margin-top: 20px;
}
.button.active {
  border-bottom: 2px solid white; /* Add a white underline for active buttons */
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 97%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
  margin-top: 20px;

}

.learn-more-button {

  margin-top: 20px; /* Adjust the margin according to your design */
}

.learn-more-button button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
  border-radius: 18px;
}



.bolding,
.overlay-text span {
  white-space: normal; /* Allow line breaks for the span element */
}

.bolding{
   font-weight: bold;
   font-family: 'Poppins', sans-serif;
}

/* Additional styles for text on the overlay */
.overlay-text1 {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 37%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 42px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap; /* Prevent line breaks */
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
  
}

.overlay-text2 {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 37%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 42px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap; /* Prevent line breaks */
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
}

.firstover {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 50%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 80%; /* Set a maximum width for the text to prevent overflow */
}

.buttons-container {
  position: absolute;
  top: 20px; /* Position the buttons 20px from the top of the image */
  right: 20px; /* Position the buttons 20px from the right of the image */
  display: flex;
  gap: 20px; /* Add some space between the buttons */
}

.button {
  background-color: transparent;
 
  color: white;
  padding: 10px 20px;

  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s ease;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Add your existing styles here */

/* Tablet Styles (768px to 1024px) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .overlay-text1,
  .overlay-text2 {
    font-size: 32px; /* Adjust font size for tablets */
    line-height: 1.4; /* Adjust line height for tablets */
  }

  .buttons-container {
    top: 15px; /* Adjust top position of buttons for tablets */
    right: 15px; /* Adjust right position of buttons for tablets */
    gap: 15px; /* Adjust space between buttons for tablets */
  }

  .button {
    font-size: 18px; /* Adjust font size for tablets */
  }
}

/* Mobile Styles (up to 767px) */
@media screen and (max-width: 767px) {
  .overlay-text1,
  .overlay-text2 {
    font-size: 18px;
    line-height: 1.3;
    /* left: 20%; */
    right: 10%;
  }

  .buttons-container {
    top: 10px; /* Adjust top position of buttons for mobile devices */
    right: 10px; /* Adjust right position of buttons for mobile devices */
    gap: 10px; /* Adjust space between buttons for mobile devices */
  }

  .button {
    font-size: 16px; /* Adjust font size for mobile devices */
  }
  .overlay {
    position: relative;
    height: 73vw;
  }
  
}
