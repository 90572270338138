.card-container {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
}

.marquee {
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  /* will-change: transform; */
    transform: translateZ(0); 
}

.marquee:hover {
  animation-play-state: paused; /* Pause the animation on hover */
}

.marquee img:first-child {
  margin-left: 0; /* Remove left margin from the first card */
}

.marquee img:last-child {
  margin-right: 0; /* Remove right margin from the last card */
}

.marquee-content {
  display: flex;
  margin-right: -20px; /* Adjust for the space between cards */
}

.marquee img {
  height: 26vh;
  width: 35%;
  /* object-fit: cover; */
  margin-right: 20px; /* Reduced margin */
  transition: transform 0.2s ease;
  margin-top: 28px;
  margin-bottom: 20px;
}

.marquee img:hover {
  transform: scale(1.05); /* Increase the size of the card slightly on hover */
}

@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}