/* Reset some default styles */
body, html {
  margin: 0;
  padding: 0;
}

/* Navbar styles */
.navbar {
  font-family: 'Poppins', sans-serif; 
  position: fixed;
  top: 32px;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
  color: white; /* Set initial text color to white */
}

.scrolled {
  background-color: white; /* Change background color to black after scrolling */
  color: #ff6600; /* Change text color to white after scrolling */
  top: 0;
}

/* Logo styles */
.logo {
  font-size: 24px;
  font-weight: bold;
  height: 65px;
  transition: color 0.3s ease-in-out; /* Add color transition for logo */
}

/* Navigation links styles */
.nav-links a {
  text-decoration: none;
  margin-right: 70px;
  font-size: 18px;
  transition: color 0.3s ease-in-out;
  margin-top: 10px;
  color: inherit; /* Inherit text color from parent */
}

.nav-links a:hover {
  color: black; /* Change the color on hover */
}

/* Mobile Menu styles */
.menu-toggle {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  padding: 5px;
  z-index: 1001;
}

.bar {
  background-color: white;
  height: 2px;
  width: 30px;
  transition: 0.3s;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Mobile Menu styles for small screens */
@media (max-width: 768px) {
  .navbar {
    background-color: white;
    top: 0;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    width: 57%;
    background-color: white;
    position: fixed;
    top: 80px;
    right: -100%;
    transition: right 0.5s ease-in-out;
    height: 100vh;
    padding-top: 20px;
    /* padding-right: 8px; */
    padding-left: 15px;
  }

  .nav-links.open {
    right: 0; /* Slide the menu in from the right when it's open */
  }

  .nav-links a {
    text-decoration: none;
    margin-right: 70px;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
    margin-top: 10px;
    color: black; /* Inherit text color from parent */
    line-height: 37px;
  }

  .menu-toggle {
    display: flex;
  }

  .menu-toggle .bar {
    background-color: black; /* Set menu toggle bars color to black for mobile view */
  }
}




