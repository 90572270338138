/* Footer.css */

/* Styles for desktop view (unchanged) */
.footer {
  background-color: white;
  padding-top: 5%;
  padding-bottom: 7%;
  padding-right: 12px;
  padding-left: 16px;
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
}

.footer-section {
  min-width: 200px; /* Set a minimum width for each section */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  margin-right: 20px; /* Add margin between sections */
}

.orange-text {
  color: #F2833E;
  font-size: 21.26px;
  font-weight: 600;
  margin-bottom: 32px;
}

.gray-text {
  color: #949490;
  line-height: 41px;
  font-size: 17px;
}

.center {
  text-align: center;
  padding-bottom: 20px;
  color: #949490;
  font-size: 20px;
  font-weight: 400;
}

.texting {
  color: #949490;
  line-height: 41px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .footer {
    justify-content: flex-start;
    padding-left: 29px;
  }

  .texting {
    color: #949490;
    line-height: 41px;
    font-size: 16px;
}

  .footer-section {
    min-width: 60px;
    margin-right: 142px;
    margin-bottom: 10px;
  }

  .foot {
    min-width: 250px; /* Adjust the minimum width for "Property Center" section */
    margin-right: 30px; /* Add more margin between "Property Center" and "Quick Links" */
    margin-bottom: 10px; /* Add margin at the bottom of "Property Center" section */
  }

  .gray-text {
    color: #949490;
    line-height: 41px;
    font-size: 15px;
  }

  .orange-text {
    margin-bottom: 10px; /* Reduce the margin-bottom for the "Company" section */
    font-size: 17.26px;
  }
}
