.testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 88vh;
    margin-top: 59px;
  }
  
  .grayBackground {
    background-color:#F8F8F8;
    padding: 20px;
    text-align: center;
    width: 100%;
    padding-top: 5%;
    padding-bottom:5%;
   
  }
  .video-container .slick-slide .video-slide {
    border-radius: 20px;
  }
  
  /* Custom styles for horizontal dots */
  .horizontal-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  
  
  /* Styles for individual video slides */
  div.video-slide {
    display: inline-block;
  
  }

  .video-slide iframe{
    border-radius: 30px;
  
  }


.iframe{
    display: inline-block;
    vertical-align: middle;
    
}  

/* Testimonials.css */
.grayBackground .video-slide .youtube-container {
  border-radius: 20px;
}

/* You can also add important to ensure the style is applied */
.grayBackground .video-slide .youtube-container {
  border-radius: 20px !important;
}
.video-slide {
  border-radius: 20px !important;
}


/* Testimonials.css */
.slick-dots li.slick-active button:before {
  color:#f2833e !important;
}


.slick-dots li button:before {
  color:#9b9292!important; /* Change dot color to gray */
 
}


@media (max-width: 767px) {
  .testimonials-container {
    height: auto;
    /* padding-top: 12%; */
    padding-bottom: 12%;
    margin-top: 10px;
  }

  .grayBackground {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .video-slide {
    max-width: 300px;
    margin: 0 auto;
  }

  .video-slide iframe {
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }
}