    /* Counter Styles */
    .counter-con {
        margin: 0;
        font-family: Arial, sans-serif;
        /* The image used */
        background-image: linear-gradient(45deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("../assets/paralax.webp"), url("../assets/paralax.webp");
        /* Set a specific height */
        min-height: 260px;
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
      }
      .countclass{
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        margin-top: 134px
      }
      .counter-icon {
        width: 70px;
        height: 72px;
        /* margin-right: 10px;
        margin-top: 95%; */
        margin-left: 40px;
        /* margin-right: 16px; */
        margin-top: 98%;
      }
      
      .counters-container {
        display: flex;
        
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 7%;
        margin-bottom: 10%;
      
        border-radius: 10px;
        position: relative;
        top: 50%;
        /* transform: translateY(-50%); */
      }
      
      .counter-item {
        display: inline-block;
        align-items: center;
        /* margin: 0 17px; */
        padding: 10px;
        border-radius: 10px;
        color: #fff;
        margin-top: 93px;
        margin-right: 17px;
      }
      
      .counter-label {
        font-weight: bold;
        margin-bottom: 10px;
        color: #fff;
        font-size: 13px;
      }
      
      .counter-value {
        font-size: 24px;
        font-weight: bold;
      }
      /* Counter.css */
      @media (max-width: 767px) {
        .counters-container {
          flex-direction: column; /* Display counters vertically */
          align-items: center;
          padding-top: 20px;
        }

        .counter-label {
          font-weight: bold;
          margin-bottom: 26px;
          color: #fff;
          font-size: 12px;
        }
      
        .counter-icon {
          margin-top: 0; /* Reset top margin for vertical layout */
          margin-bottom: 10px; /* Add margin between icon and counter item */
          display: flex;
          margin-right: 211px;
          width: 54px;
          height: 60px;
        }
      
        .counter-item {
          margin-right: 0; /* Reset right margin for vertical layout */
          text-align: center; /* Center text in vertical layout */
          margin-bottom: 20px; 
          display: inline-block;
          align-items: center;
          /* margin: 0 17px; */
          /* padding: 10px; */
          border-radius: 10px;
          color: #fff;
          margin-top: -81px;
          
          margin-left: 41px;
        }
      
        .countclass {
          font-size: 27px !important; /* Adjust font size for mobile devices */
          margin-top: 10px; /* Adjust top margin for better visibility */
        }
      }
      
      
      
