.con{
         position: relative;
        text-align: center;
       }
    
     
       .image-container {
         width: 120%;
       }
       .h-120p {
         height: 120%;
       }

       .slider:hover .slick-slide {
        transition: none !important;
      }

      .custom-carousel {
        animation-play-state: paused; /* Pause the animation on hover */
      }
      
      
       .imghi{
         height: auto;
         border-radius: 10px;
         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
         transform: scale(0.8); 
         transition: transform 0.3s ease;
         border-radius: 41px;
         z-index: 9999;
       }
       .imghi:hover {
         transform: scale(0.95); 
       }
       .slick-dots li button:before {
         font-size: 12px;
         color: #f6edd4;
       }
      
       .slick-dots li.slick-active button:before {
         color: #a98b39
       }
      
    
       :root {
         --carousel-perspective: 1000px; 
       }
      
       .imghi {
         transform-origin: center center var(--carousel-perspective); 
       
       }
       .image-title {
         margin-top: 20px;
         margin-bottom: 20px;
         text-align: center;
         font-weight: bold;
         font-size: 22px;
         margin-right: 45px;
       }
       .head{
         text-align: center;
          margin-top: 83px;
         margin-bottom: 27px;
         font-size: 36px;
    /*      font-weight: bold; */
         color: rgb(179, 55, 104); 
         text-decoration: underline; 
          text-decoration-color: rgba(8, 77, 108); 
        }
        .slick-next {
         right: auto;
        
       }
        .slick-next:before{
         display: none;
       }
      
       .cust-width{
        width: 437px;
       }
    
    /* Mobile Styles */
     @media (max-width: 768px) {
       .imghi {
           height: auto;
           width: 100%; 
           margin-right: 0;
            border-radius: 24px;  
           border-radius: 41px
       }
    
       .image-title {
           font-size: 18px; 
           margin: 10px 0; 
       }
       .head {
           font-size: 18px; 
           margin: 20px 0; 
       }
     }