.backimg {
    height: 624px;
    width: 100%;
  }
  .para{
    height: 157px;
    text-align: center;
    margin-top: 91px;
    font-size: 37px;
    font-weight: 200;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;   
  
  }
  .fade-in-text {
  
    font-size: 36px;
    animation: fadeIn 3s;
  }
  
  .fade-out {
    
    font-size: 36px;
    animation: fadeout 3s;
  }
  

 
  .icon{
    margin-top: 4px;
  }

  

  
  .para:not(:first-child) {
    opacity: 0;
  }
  .bold{
    font-weight: bold;
  }
  .background-container {
    position: relative;
    text-align: center;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
   
  @media (max-width: 768px) {
    .para {
      height: 92px;
      font-size: 24px;
      margin-top: 60px;
    }

    .custom-padding {
      padding: 0.6rem;
    }
    .given{
      width: 135%;
    }

    .w-custom{
    width: 128%;
    gap: 2rem;
    padding-right: 95px;
    }
  
    .para .bold {
      font-weight: bold;
    }
    .backimg {
      height: 451px;
      width: 100%;
  }
  }

  
  
  /* Tablet styles for the text paragraphs (adjust the max-width as needed) */
  @media (max-width: 1024px) {
    .para {
      font-size: 20px;
      margin-top: 50px;
     
    }
    .given{
      width: 135%;
      margin-bottom: 103px;
    }

  }
  
  
  
  
  

  

  
  
  
  
  
  