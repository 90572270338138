* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logos-slide img {
    height: 81px;
    /* margin-right: 20px;  */
}

.logos {
    height: 155px; /* Adjust the height as needed */
    
    margin-top: 6%;
    margin-bottom: 8%;
    white-space: nowrap;
    position: relative;
    overflow: hidden; 
    overflow-x: hidden; 
}

.vtp {
    margin-right: 31px;
    margin-left: 21px;
}

.logos::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 307px;
    height: 150%; 
    background-color: #EEEEEE;
    z-index: 2;
    content: "Our Brand";
    color: #999995;
    font-size: 32px;
    padding: 21px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-right: 17px;
    transform: translateY(2px);
    padding-left: 74px;
    
}

.logos::after {
    position: absolute;
    top: 60px; /* Adjust the top position to move "Partnership" below "Our Brand" */
    left: 0;
    width: 307px;
    height: 150%;
    background-color: #EEEEEE;
    z-index: 2;
    content: "Partnerships";
    color: #F2833E;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-left: 96px;
}



.logos-slide {
    display: inline-block;
    animation: 10s slide infinite linear; /* Adjust duration as needed */
    margin-top: 38px;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}
